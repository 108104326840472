// Campaign Landing Page Two Script File
/*global $:false, jQuery:false*/
/*jslint browser: true*/

function addVideo() {
	'use strict';

	var customWEBM = "/app/files/public/jenn_and_jay_w-fx_001.webm",
		customOGV = "/app/files/public/jenn_and_jay_w-fx_001.ogv",
		customMP4 = "/app/files/public/2083/video-nchv-John-Kavanaugh_NoFx_001.mp4",
		fallbackimg = "/app/files/public/Templates-Campaing-Landing-Two-Banner.jpg",

		browserDetect = {
			init: function () {
				this.browser = this.searchString(this.dataBrowser) || "Other";
				this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
			},
			searchString: function (data) {
				for (var i = 0; i < data.length; i++) {
					var dataString = data[i].string;
					this.versionSearchString = data[i].subString;

					if (dataString.indexOf(data[i].subString) !== -1) {
						return data[i].identity;
					}
				}
			},
			searchVersion: function (dataString) {
				var index = dataString.indexOf(this.versionSearchString);
				if (index === -1) {
					return;
				}

				var rv = dataString.indexOf("rv:");
				if (this.versionSearchString === "Trident" && rv !== -1) {
					return parseFloat(dataString.substring(rv + 3));
				} else {
					return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
				}
			},
			dataBrowser: [
				{ string: navigator.userAgent, subString: "Edge", identity: "MS Edge" },
				{ string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
				{ string: navigator.userAgent, subString: "MSIE", identity: "Explorer" },
				{ string: navigator.userAgent, subString: "Trident", identity: "Explorer" },
				{ string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
				{ string: navigator.userAgent, subString: "Safari", identity: "Safari" },
				{ string: navigator.userAgent, subString: "Opera", identity: "Opera" }
			]
		};

	browserDetect.init();

	if (typeof webmVideo !== 'undefined') {
		customWEBM = webmVideo;
	}
	if (typeof ogvVideo !== 'undefined') {
		customOGV = ogvVideo;
	}
	if (typeof mp4Video !== 'undefined') {
		customMP4 = mp4Video;
	}
	if (typeof imageOption !== 'undefined') {
		fallbackimg = imageOption;
	}
	if (browserDetect.browser === "Explorer" && browserDetect.version === 9) {
		$("#CampaignTwo header").backstretch(fallbackimg);
	} else {
		if ($("#CampaignTwo").length) {
			if (Modernizr.touch) {
				$("#CampaignTwo .VideoBanner").backstretch(imageOption);
				$('<div class="BannerOverlay"></div>').prependTo('.VideoBanner');
			} else if (Modernizr.video) {

				$("#CampaignTwo .VideoBanner").poosh([
					{ type: "video/webm", src: customWEBM },
					{ type: "video/ogg", src: customOGV },
					{ type: "video/mp4", src: customMP4 }
				], {
						cover: imageOption,
						centeredY: false,
						fade: false,
						controls: true,
						overlay: true
					});
			} else {
				$("#CampaignTwo .VideoBanner").backstretch(imageOption);
				$('<div class="BannerOverlay"></div>').prependTo('.VideoBanner');
			}
		} else {
			if ($("#CampaignTwo .VideoBanner").length) {
				$("#CampaignTwo .VideoBanner").backstretch(imageOption);
				$('<div class="BannerOverlay"></div>').prependTo('.VideoBanner');
				//$("#TopBackground").remove();
			} else {
				$("#CampaignTwo .VideoBanner").backstretch(imageOption);
				$('<div class="BannerOverlay"></div>').prependTo('.VideoBanner');
			}
		}
	}
}

/* Count up to number Script - used on campaign template two
------------------------------------------------------------------------*/
(function ($) {
	$.fn.countTo = function (options) {
		options = options || {};

		return $(this).each(function () {
			// set options for current element
			var settings = $.extend({}, $.fn.countTo.defaults, {
				from: $(this).data('from'),
				to: $(this).data('to'),
				speed: $(this).data('speed'),
				refreshInterval: $(this).data('refresh-interval'),
				decimals: $(this).data('decimals')
			}, options);

			// how many times to update the value, and how much to increment the value on each update
			var loops = Math.ceil(settings.speed / settings.refreshInterval),
				increment = (settings.to - settings.from) / loops;

			// references & variables that will change with each update
			var self = this,
				$self = $(this),
				loopCount = 0,
				value = settings.from,
				data = $self.data('countTo') || {};

			$self.data('countTo', data);

			// if an existing interval can be found, clear it first
			if (data.interval) {
				clearInterval(data.interval);
			}
			data.interval = setInterval(updateTimer, settings.refreshInterval);

			// initialize the element with the starting value
			render(value);

			function updateTimer() {
				value += increment;
				loopCount++;

				render(value);

				if (typeof (settings.onUpdate) == 'function') {
					settings.onUpdate.call(self, value);
				}

				if (loopCount >= loops) {
					// remove the interval
					$self.removeData('countTo');
					clearInterval(data.interval);
					value = settings.to;

					if (typeof (settings.onComplete) == 'function') {
						settings.onComplete.call(self, value);
					}
				}
			}

			function render(value) {
				/*var formattedValue = settings.formatter.call(self, value, settings);
				$self.html(formattedValue);*/

				var formattedValue = settings.formatter.call(self, value, settings);
				if ($self.hasClass('Percent')) {
					$self.html(formattedValue + "%");
				} else {
					$self.html(formattedValue);
				}
			}

		});
	};

	$.fn.countTo.defaults = {
		from: 0,               // the number the element should start at
		to: 0,                 // the number the element should end at
		speed: 500,           // how long it should take to count between the target numbers
		refreshInterval: 100,  // how often the element should be updated
		decimals: 0,           // the number of decimal places to show
		formatter: formatter,  // handler for formatting the value before rendering
		onUpdate: null,        // callback method for every time the element is updated
		onComplete: null       // callback method for when the element finishes updating
	};

	function formatter(value, settings) {
		return value.toFixed(settings.decimals);
	}

}(jQuery));

// Equal Height Script
var equalHeight = (function ($) {
	"use strict";

	// remove inline min height from the group
	function clear(group) {
		group.css('min-height', '');
	};

	// make group equal heights
	function equalHeight(group) {

		// reset height set by this script
		clear(group);

		// defining a variable that will keep track of the height of the tallest element
		var tallest = 0;

		// loop through elements, find the tallest outer height (includes padding)
		group.each(function () {

			// this is the outer height of the element (it doesn't round up or down to whole numbers)
			var thisHeight = this.getBoundingClientRect().bottom - this.getBoundingClientRect().top;

			if (thisHeight > tallest) {
				tallest = thisHeight;
			}
		});

		// loop through elements again, individually set their min-height so that their total height (including padding) = our tallest element
		group.each(function () {

			// if this has css box-sizing: border box, set the min-height equal to the tallest
			if (isBorderBox(this)) {

				group.css('min-height', Math.ceil(tallest));

			} else {

				// if an element has padding
				if ($(this).outerHeight() > $(this).height()) {

					// calulate how much border and padding is on the element
					var thisPadding = $(this).outerHeight() - $(this).height();

					// set the height of the element to the tallest, but leave room for the padding
					group.css('min-height', Math.ceil(tallest - thisPadding));

				} else {

					// if the element has no padding, simply set the height to our tallest
					group.css('min-height', Math.ceil(tallest));
				}
			}
		});
	};

	// check to see if the page is using box-sizing: border-box;
	function isBorderBox(elem) {
		return window.getComputedStyle(elem).boxSizing === "border-box";
	};

	return {
		equalHeight: equalHeight,
		outerHeight: equalHeight,
		clear: clear
	};

})(jQuery);


$(function () {
	'use strict';
	$('body').removeClass('NoScript');

	// Look for Accent Color
	var AccentColor = $('#AccentColor').html();
	if (AccentColor !== undefined) {
		$('body').addClass(AccentColor);
	}

	// If quotes are on the page - add a gray background to that row
	if ($('.cmsPanelContent .QuoteWrap').length) {
		$('.cmsPanelContent .QuoteWrap').closest('div.CampaignRowWrap').addClass('GrayBackground');
	}

	// If podcast are on the page - add a blue background to that row
	if ($('.cmsPanelContent .Podcast').length && $('body').hasClass('Gray')) {
		$('.cmsPanelContent .Podcast').closest('div.CampaignRowWrap').addClass('BlackBackground');
	} else if ($('.cmsPanelContent .Podcast').length) {
		$('.cmsPanelContent .Podcast').closest('div.CampaignRowWrap').addClass('LightBlueBackground');
	}

	// If Statistics are on the page - add a class to that row
	if ($('.cmsPanelContent .Stat').length) {
		$('.cmsPanelContent .Stat').closest('div.CampaignRowWrap').addClass('Statistics');
	}

	$('.CampaignRow').each(function () {

		// If a CampaignRow is empty remove it
		if ($.trim($(this).html()) === '') {
			$(this).remove();
		}

		// If a CampaignRow has a background image panel - apply the background
		var BackgroundImage = $(this).find('.cmspanel .BackgroundImage').html();
		var BackgroundImageLight = $(this).find('.cmspanel .BackgroundImageLight').html();
		var BackgroundImageSource = '';

		if (typeof BackgroundImage !== 'undefined') {
			BackgroundImageSource = $(this).find('.cmspanel .BackgroundImage img').attr('src');

			// add background image to CampaignRowWrap -- Using backstretch plugin 
			$(this).find('.cmspanel .BackgroundImage').closest('.CampaignRowWrap').addClass('BackgroundPanel').backstretch(BackgroundImageSource);

			// remove background image panel
			$(this).find('.cmspanel .BackgroundImage').closest('.cmspanel').remove();
		}

		if (typeof BackgroundImageLight !== 'undefined') {
			BackgroundImageSource = $(this).find('.cmspanel .BackgroundImageLight img').attr('src');

			// add background image to CampaignRowWrap -- Using backstretch plugin 
			$(this).find('.cmspanel .BackgroundImageLight').closest('.CampaignRowWrap').addClass('BackgroundPanelLight').backstretch(BackgroundImageSource);

			// remove background image panel
			$(this).find('.cmspanel .BackgroundImageLight').closest('.cmspanel').remove();
		}


		// Count the number of panels in each CampaignRow and adjust css accordingly
		var NumOfPanels = $(this).find('.cmspanel').length;

		if (NumOfPanels === 1) {
			$(this).find('.cmspanel').addClass('OnePanel');
		}
		if (NumOfPanels === 2) {
			$(this).find('.cmspanel').addClass('TwoPanels');
		}
		if (NumOfPanels === 3) {
			$(this).find('.cmspanel').addClass('ThreePanels');
		}
		if (NumOfPanels === 4) {
			$(this).find('.cmspanel').addClass('FourPanels');
		}
	});

	// Flip Cards on Click
	$('.Card .Back').each(function () {
		$(this).hide();
	});
	$('.Card').each(function () {
		$(this).attr('tabindex', '0');
	});

	$('.Card').on("click keypress", function (e) {
		if (e.type != "keypress" || e.keyCode == 13) {
			$(this).toggleClass('Flip');
			$(this).find('.Back').toggle();
			$(this).find('.Front').toggle();
		}
	});

	//Provider Smart Panel - use larger images
	if ($('#DrSmartPanel').length){
		$('#DrSmartPanel li.SPItem dd.Picture img').each(function() {
			if (this.src.indexOf("female") != -1) {
				$(this).attr('src', $(this).attr('src').replace('silhouette_female','silhouette_femalebig'));
			} else if (this.src.indexOf("male") != -1) {
				$(this).attr('src', $(this).attr('src').replace('silhouette_male','silhouette_malebig'));
			} else {
				$(this).attr('src', $(this).attr('src').replace('?size=small',''));
			}
		});		
	}	

	$('#CampaignTwo.Gray .BackgroundPanel .cmspanel').css('height', 'auto');
	//make columns equal heigh
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowOne.BackgroundPanel .cmspanel'));
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowTwo.BackgroundPanel .cmspanel'));
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowThree.BackgroundPanel .cmspanel'));
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowFour.BackgroundPanel .cmspanel'));
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowFive.BackgroundPanel .cmspanel'));
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowSix.BackgroundPanel .cmspanel'));
	equalHeight.equalHeight($('#CampaignTwo.Gray #RowSeven.BackgroundPanel .cmspanel'));

	// add Video
	if ($('#BannerWrap .VideoBanner, #Banner .VideoBanner').length) {
		addVideo();
	}

	/* Podcast
	----------------------------------------------------------------------------------------- */
	if ($('.Podcast').length) {
		// variables for initial track
		var currentTrackTitle = $('.Podcast .AudioFile a').text();
		var currentTrackURL = $('.Podcast .AudioFile a').attr('href');
		var currentTranscript = $('.Podcast .TranscriptFile a').attr('href');

		$('.Podcast').html('<div class="player">' +
			'	<div class="player-info">' +
			'		<div class="player-info-text">' +
			'			<div class="PageWidth">' +
			'				<p class="player-track-title">' + currentTrackTitle + '</p>' +
			'				<p class="player-transcript"><a class="FancyBox" href="' + currentTranscript + '">View Transcript</a></p>' +
			'			</div>' +
			'		</div>' +
			'	</div>');

		function initAudio() {

			var supportsAudio = !!document.createElement('audio').canPlayType;
			var audio,
				loadingIndicator,
				positionIndicator,
				timeleft,
				loaded = false,
				manualSeek = false;

			if (supportsAudio) {

				var player = '<div class="player-controls" role="application">' +
					'<button id="playtoggle" title="Play" class="play-pause-button">Play</button>' +
					'<span id="gutter">' +
					'<span id="loading" />' +
					'<span tabindex="0" id="handle" class="ui-slider-handle" role="slider" aria-labelledby="timeleft" aria-valuemin="0" aria-valuenow="0" aria-valuemax="100" />' +
					'</span>' +
					'<span id="timeleft" />' +
					'<audio preload="auto">' +
					'<source src="' + currentTrackURL + '" type="audio/mpeg"></source>' +
					'</audio>' +
					'</div>';

				$(player).prependTo(".player");

				audio = $('.player-controls audio').get(0);
				loadingIndicator = $('.player-controls #loading');
				positionIndicator = $('.player-controls #handle');
				timeleft = $('.player-controls #timeleft');

				if (audio !== undefined) {
					if ((audio.buffered !== undefined) && (audio.buffered.length !== 0)) {
						$(audio).on('progress', function () {
							var loaded = parseInt(((audio.buffered.end(0) / audio.duration) * 100), 10);
							loadingIndicator.css({ width: loaded + '%' });
						});
					}
					else {
						loadingIndicator.remove();
					}
				}


				$(audio).on('timeupdate', function () {

					$('#handle').attr('aria-valuenow', audio.currentTime);
					$('#handle').attr('aria-valuemax', audio.duration);

					var rem = parseInt(audio.duration - audio.currentTime, 10),
						pos = (audio.currentTime / audio.duration) * 100,
						mins = Math.floor(rem / 60, 10),
						secs = rem - mins * 60;

					timeleft.text('' + mins + ':' + (secs < 10 ? '0' + secs : secs));
					if (!manualSeek) { positionIndicator.css({ left: pos + '%' }); }
					if (!loaded) {
						loaded = true;

						$('.player-controls #gutter').slider({
							value: 0,
							step: 10,
							orientation: "horizontal",
							range: "min",
							max: audio.duration,
							animate: true,
							slide: function () {
								manualSeek = true;
							},
							stop: function (e, ui) {
								manualSeek = false;
								audio.currentTime = ui.value;
							}
						});
					}

				}).on('play', function () {
					$("#playtoggle").addClass('playing');
				}).on('pause ended', function () {
					$("#playtoggle").removeClass('playing');
				});

				$("#playtoggle, .play-button").click(function () {
					if (audio.paused) {
						audio.play();
					}
					else {
						audio.pause();
					}

					paused = !paused;

					$('.play-pause-button').text('Play').attr('title', 'Play');
					if (!paused) {
						start();
						$('.play-pause-button').text('Pause').attr('title', 'Pause');
					}

					return false;
				});

				if (audio !== undefined) {
					audio.addEventListener("ended", function () {
						audio.currentTime = 0;
						audio.pause();
						paused = !paused;
						$('.ui-slider-range').css('width', '0');
						$('.play-pause-button').text('Play');
					});
				}
			}
		}

		initAudio();
	}

	// FancyBox
	$("a.FancyBox").modaal({
		type: 'iframe',
		width: 1000
	});

	/* Golf Articles - Single Filter Hub - Related Content SmartPanel - Task 696994
	----------------------------------------------------------------------------------------- */
	if ($('.cmsPanelContent .GolfStories').length) {
		$('.cmsPanelContent .GolfStories').closest('.cmspanel').addClass('GolfHubStories');

		$('.GolfHubStories .SPList li.SPItem .Thumbnail img').each(function() {			
			$(this).attr('src', $(this).attr('src').replace('?size=small',''));
		});	

		$('.GolfHubStories li.SPItem').each(function() {
			$(this).find('.Thumbnail').prependTo($(this));
			$(this).find('.PublicationDate').insertAfter($(this).find('.Thumbnail'));
			$(this).find('.PublicationDate, a').wrapAll("<div class='ArticleWrap'></div>");
		});

		$('.cmsPanelContent div.GolfStories').remove();
	}

});

$(window).load(function (){
	/* Run Counter for Statistics
	----------------------------------------------------------------------------------------- */
	// custom formatting example
	$('.count-number').data('countToOptions', {
		formatter: function (value, options) {
			return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
		}
	});

	function count(options) {
		var $this = $(this);
		options = $.extend({}, options || {}, $this.data('countToOptions') || {});
		$this.countTo(options);
	}

	var counter = 0;
	var coordinates = $('.Statistics').offset();	

	$(window).scroll(function () {
		if(coordinates) {
			var myPosition = $(document).scrollTop();
			if (myPosition >= coordinates.top - 450) {
				if (counter === 0) {
					// start all the timers
					$('.timer').each(count);
				}
				counter = 1;
			}
		}
	});
});


//Logo Owl Carousel equal height and padding for vertical alignment
function matchHeight(parentElement, childElement) {
	var height = 0;
	var padding = 0;
	$(parentElement).find(childElement).css("padding-top", 0).css("padding-bottom", 0);
	$(parentElement).find(childElement).each(function () {
		height = $(this).height() > height ? $(this).height() : height;
	});
	$(parentElement).find(childElement).each(function () {
		if ($(this).height() < height) {
			var padding = parseInt((height - $(this).height()) / 2);
			$(this).css("padding-top", padding).css("padding-bottom", padding);
		}
	});
}

//Sort random function
function random(owlSelector) {
	owlSelector.children().sort(function () {
		return Math.round(Math.random()) - 0.5;
	}).each(function () {
		$(this).appendTo(owlSelector);
	});
}

/* Manually Setting Locations and Google Map
----------------------------------------------------------------------------------------- */

// Google Map Javascript API Key required for this found in page's custom HTML tab
function initializeMap(locations) {
	// Map will center on the first location entered
	var firstlocation = locations[0];
	var firstlatlng = ({ lat: firstlocation[1], lng: firstlocation[2] });

	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 10,
		center: firstlatlng
	});

	setMarkers(locations, map);
}

function setMarkers(locations, map) {
	// if all the locations have the exact same address we will omit the 'fitBounds'. Ensure we have different locations.
	var differentAddress = false;
	var latitudes = [];
	var longitudes = [];

	// create new google map info window
	var infowindow = new google.maps.InfoWindow(), marker, i;

	// bounds will make sure that all the markers on the map fit within the viewport
	var bounds = new google.maps.LatLngBounds();

	// Adds markers to the map
	for (var i = 0; i < locations.length; i++) {
		var location = locations[i];

		// if we have more than one location check to see if they have different addresses
		if (i > 0) {
			if ($.inArray(location[1], latitudes) === -1 && $.inArray(location[2], longitudes) === -1) {
				differentAddress = true;
			}
		}

		latitudes.push(location[1]);
		longitudes.push(location[2]);

		// get lat and lng and add to the bounds variable
		var point = new google.maps.LatLng(location[1], location[2]);
		bounds.extend(point);

		// Build out the marker
		var marker = new google.maps.Marker({
			position: { lat: location[1], lng: location[2] },
			map: map,
			title: location[0]
		});

		// build out the content in the info window
		google.maps.event.addListener(marker, 'click', (function (marker, i) {
			var contentString = '<div id="content">' +
				'<div id="siteNotice">' +
				'</div>' +
				'<h3 id="firstHeading" class="firstHeading">' + location[0] + '</h3>' +
				'<div id="bodyContent">' +
				'<p class="Address">' + location[4] + '&nbsp;' + location[5] + '</p>' +
				'<p class="Address">' + location[6] + ',&nbsp;' + location[7] + '&nbsp;' + location[8] + '</p>' +
				'<p class="phone">' + location[9] + '</p>' +
				'</div>' +
				'</div>';

			return function () {
				infowindow.setContent(contentString);
				infowindow.open(map, marker);
			}
		})(marker, i));
	}
	if (locations.length > 1 && differentAddress) {
		// zoom to the bounds
		map.fitBounds(bounds);
	}
}

// Google map height
function googleMapHeight() {
	"use strict";

	if ($('body#CampaignTwo').length) {
		if ($(window).width() > 1024) {
			var LocationHeight = $('.LocationWrapper').height();
			$('.MapWrapper, #ClientLocationsMap, .MapWrapper iframe, #map, .GMap').css('height', LocationHeight);
		} else {
			$('.MapWrapper, #ClientLocationsMap, .MapWrapper iframe, #map, .GMap').css('height', '320px');
		}
	} else {
		$('.MapWrapper, #ClientLocationsMap, .MapWrapper iframe, #map, .GMap').css('height', '320px');
	}
	
}

/* STICKY NAV
---------------------------------------------------------------------------- */
function stickyNavigation() {
	"use strict";
	var $window = $(window),
		$navigation = $(".HeaderTopWrap"),
		offsetnav = $navigation.offset(),
		navHeight = $navigation.outerHeight();

	$window.on('scroll', function () {
		offsetnav = $navigation.offset() || 0;
		navHeight = $navigation.outerHeight() || 0;
		if (($window.scrollTop() > offsetnav.top) && $('.Medium , .Large, .XL').length){
			$('#LocationJumpNav').addClass('Fixed');
			$navigation.css('height', navHeight);
		} else {
			$('#LocationJumpNav').removeClass('Fixed');
			$navigation.removeAttr('style');
		}
	});
}

// window resize event
var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) {
			uniqueId = "unc-location";
		}
		if (timers[uniqueId]) {
			clearTimeout(timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

$(document).on('ready', function () {

	//only run this on the campaign two templates
	if ($('body#CampaignTwo').length) {
		stickyNavigation();

		var LocationHeight = $('.LocationWrapper').height();
		$('.MapWrapper').css('height', LocationHeight);

		
	} 

	googleMapHeight();

	//Equal Height for Items in Slider
	matchHeight(".LogoSlider", ".owl-item");
	$(window).on('resize', function() {
		matchHeight(".LogoSlider", ".owl-item");
	});

	moveElements();

	if ($('body.Map .CampaignLocations #LocationId').length) {
		$('<div class="LocationWrapper"><div class="MapWrapper"><div id="map"></div></div><div class="LocationInfoWrapper"><div class="LocationInfo"><div class="ContactInfo"></div></div></div></div>').appendTo('body.Map .CampaignLocations');
	}	

	//Remove mobile nav trigger if no navigation
	if ( $('#CampaignTwo #MainNavWrap').children().length == 0 ) {
		$('#HeaderTop .MenuButton').remove();
	} else {
		$('#CampaignTwo #HeaderTop').addClass('WithMenu');
	}


	$('#CampaignTwo #PanelSmartPanelRelatedPhysicians .MoreLink > a').text('View More Providers');
});

var width = $(window).width();
$(window).on('resize', function() {
	'use strict';
	waitForFinalEvent(function () {
		if ($(this).width() !== width) {
			width = $(this).width();
			googleMapHeight();
		} else {
		}
	}, 250, "unc-location");
});

// Run all this script after the page loads otherwise you get inconsitencies between IE and other browsers.
$(window).load(function () {
	// insert preloader images
	if (!$('#LocationHours #loadingmessage').length) {
		$("<div id='loadingmessage' ><img src ='/imgs/slider_preloader.gif' / ></div>").appendTo('.Locations');
	}
	if (!$('#OurProviders #loadingmessage').length) {
		$("<div id='loadingmessage' ><img src ='/imgs/slider_preloader.gif' / ></div>").prependTo('#OurProviders');
	}

	// Get Navigation Node from the page
	var NavNode = Geonetric.Page.NavigationNode;

	// Get Location id from the cmspage
	if ($('#LocationId').length) {

		var ThisLocationId = $('#LocationId').text(),
			locationsArray = ThisLocationId.split(', '),
			markersArray = [],
			locId = [],
			locTermId = [],
			count = locationsArray.length;

		//		if (count > 2) {
		//			$('.RelatedLocations').wrapInner('<ul class="LocationWrap"></ul>').prepend('<h3 class="sr-only">Related Locations</h3>');
		//		} else {
		//			$('.RelatedLocations').prepend('<h3 class="sr-only">Related Location</h3>');
		//		}

		// Add container for related locations if there is more than one location		
		if (count > 1) {
			var RelatedLocations = '<div class="RelatedLocations"><h3 class="sr-only">Related Locations</h3><ul class="LocationWrap ' + (count > 5 ? "Overflow" : "") + '"></ul></div>';
			$('.LocationInfoWrapper .LocationInfo').append(RelatedLocations);
		}

		$(locationsArray).each(function (index) {
			// Pull in data from nancy
			$.get('/nancy/locations/' + locationsArray[index] + '/viewmodel.json', function (data) {

				//Loop through address array
				var locNameText = (data.Name),
					locAddress = (data.Address.AddressLine1),
					locAddress2 = (data.Address.AddressLine2),
					locCity = (data.Address.City),
					locState = (data.Address.StateAbbreviation),
					locStateName = (data.Address.StateName),
					locZip = (data.Address.PostalCode),
					drivingDirections = (data.GoogleDrivingDirectionsUrl),
					locImage = '',
					locImageFile = (data.Images[0] && data.Images[0].PublicHRef ? data.Images[0].PublicHRef : null),
					webSiteLink = (data.WebSiteUrl);

				// Get other phone numbers
				var phoneMain = (data.PhoneNumbers.Main.WholeNumber),
					phoneAlt = (data.PhoneNumbers.Alternate.WholeNumber),
					phoneOffice = (data.PhoneNumbers.Office.WholeNumber),
					phoneMobile = (data.PhoneNumbers.Mobile.WholeNumber),
					phoneFax = (data.PhoneNumbers.Fax.WholeNumber),
					phonePager = (data.PhoneNumbers.Pager.WholeNumber);

				// Get other location information
				var locOldHours = (data.HoursOfOperation),
					locNewHours = (data.OfficeHoursList),
					locComment = (data.OfficeHourComment),
					locLat = (data.Latitude),
					locLong = (data.Longitude);

				// Get Location IDs
				locId.push(data.LocationId);
				locTermId.push(data.Term.TermId);

				// Add the data we need for Google Maps to an array
				// i+1 is the zindex for each location
				markersArray.push([locNameText, locLat, locLong, index + 1, locAddress, locAddress2, locCity, locState, locZip, phoneMain]);

				if (locImageFile !== null) {
					locImage = '<img src="' + locImageFile.replace('~', '') + '" alt="" itemprop="image" />';
				}

				if (drivingDirections !== null) {
					drivingDirections = '<a target="_blank" class="DDLink" href="' + drivingDirections + '">Get Driving Directions<span class="sr-only"> to ' + locNameText + ', opens new tab</span></a>';
				}

				var ContactInformation = '',
					PhoneNumbers = '',
					Hours = '';

				if (index == 0) {
					ContactInformation = '<h3>Contact Information</h3>' +
						'<div class="vcard">';
					if (locImage.length) {
						ContactInformation = ContactInformation + locImage;
					}

					ContactInformation = ContactInformation + '<div class="adr">';

					if (webSiteLink.length) {
						ContactInformation += '<p><strong><a href="' + webSiteLink + '">' + locNameText + '</a></strong>';
					} else {
						ContactInformation += '<p><strong>' + locNameText + '</strong>';
					}

					if (locAddress.length) {
						ContactInformation = ContactInformation + '<span class="street-address">' + locAddress + '</span>';
					}
					if (locAddress2.length) {
						ContactInformation = ContactInformation + '<span class="street-address">' + locAddress2 + '</span>';
					}
					if (locCity.length && locState.length && locZip.length) {
						ContactInformation = ContactInformation + '<span class="locality">' + locCity + '</span><text>,</text> <span class="region"><abbr title="' + locStateName + '">' + locState + '</abbr></span> <span class="postal-code">' + locZip + '</span>';
					} else {
						if (locCity.length) {
							ContactInformation = ContactInformation + '<span class="locality">' + locCity + ',&nbsp;</span>';
						}
						if (locState.length) {
							ContactInformation = ContactInformation + '<span class="region"><abbr title="' + locStateName + '">' + locState + '</abbr>&nbsp;</span>';
						}
						if (locZip.length) {
							ContactInformation = ContactInformation + '<span class="postal-code">' + locZip + '</span>';
						}
					}
					if (drivingDirections.length) {
						ContactInformation = ContactInformation + '</p><p>' + drivingDirections;
					}

					ContactInformation = ContactInformation + '</p></div></div>';

					if (phoneMain !== null || phoneAlt !== null || phoneOffice !== null || phoneMobile !== null || phoneFax !== null || phonePager !== null) {

						PhoneNumbers = '<ul class="Plain PhoneNumbers">';

						if (phoneMain !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="MainTelephoneNumber">' +
								'<span class="TelLabel Hide"><strong>Main:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneMain + '" aria-label="' + phoneMain + ' ' +locNameText + ' Phone Number">' + phoneMain + '</a></span>' +
								'</li>';
						}
						if (phoneAlt !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="AlternateTelephoneNumber">' +
								'<span class="TelLabel"><strong>Alternate:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneAlt + '" aria-label="Call on ' + phoneAlt + '">' + phoneAlt + '</a></span>' +
								'</li>';
						}
						if (phoneOffice !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="OfficeTelephoneNumber">' +
								'<span class="TelLabel"><strong>Office:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneOffice + '" aria-label="Call on ' + phoneOffice + '">' + phoneOffice + '</a></span>' +
								'</li>';
						}
						if (phoneMobile !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="MobileTelephoneNumber">' +
								'<span class="TelLabel"><strong>Mobile:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneMobile + '" aria-label="Call on ' + phoneMobile + '">' + phoneMobile + '</a></span>' +
								'</li>';
						}
						if (phoneFax !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="MobileTelephoneNumber">' +
								'<span class="TelLabel"><strong>Fax:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneFax + '" aria-label="Fax at ' + phoneFax + '">' + phoneFax + '</a></span>' +
								'</li>';
						}
						if (phonePager !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="PagerNumber">' +
								'<span class="TelLabel"><strong>Pager:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phonePager + '">' + phonePager + '</a></span>' +
								'</li>';
						}
						PhoneNumbers = PhoneNumbers + '</ul>';
					}

					// See if location has new hours set and times exist
					var hasNewHours = false;

					for (var day in locNewHours) {
						if (locNewHours[day].OfficeHours.length > 0) {
							hasNewHours = true;
						}
					}

					// Add office hours markup
					if (hasNewHours) {
						Hours = '<div class="Hours NewHours"><h3>Business Hours</h3>';

						for (day in locNewHours) {
							var theHours = locNewHours[day].OfficeHours;

							if (theHours.length === 0) {
								Hours += '<div class="DaySchedule">' +
									'<div class="Day">' + day + '</div>' +
									'<div class="Times">' + 'Closed' + '</div>' +
									'</div>';
							} else {
								for (var i = 0; i < theHours.length; i++) {
									Hours += '<div class="DaySchedule">' +
										'<div class="Day">' + day + '</div>' +
										'<div class="Times">' + convertHours(theHours[i].OpenTime) + '<div class="RangeSeparator"> - </div>' + convertHours(theHours[i].CloseTime) + '</div>' +
										'</div>';
								}
							}
						}

						if (locComment.length) {
							Hours += '<div class="OfficeHourComment">' + locComment + '</div>';
						}

						Hours += '</div>';
					} else if (locOldHours.length && locComment.length) {
						Hours = '<div class="Hours"><h3>Business Hours</h3>';
						Hours += '<div class="OfficeHourComment">' + locComment + '</div>';
						Hours += '</div>';
					} else if (hasNewHours == false && locComment.length) {
						Hours = '<div class="Hours"><h3>Business Hours</h3>';
						Hours += '<div class="OfficeHourComment">' + locComment + '</div>';
						Hours += '</div>';
					} else {
						Hours = '<div class="Hours"><h3>Business Hours</h3>';
						Hours += '<div class="DaySchedule">' + locOldHours + '</div>';
						Hours += '</div>';
					}

					// Convert 24 hour times (16:00) to 12 hour AM/PM times (4:00 PM)
					function convertHours(time) {
						// Check correct time format and split into components
						time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

						if (time.length > 1) { // If time format correct
							time = time.slice(1);  // Remove full string match value
							time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
							time[0] = +time[0] % 12 || 12; // Adjust hours
						}
						return time.join(''); // return adjusted time or original string
					}

					$('.LocationInfoWrapper .ContactInfo:first').append(ContactInformation, PhoneNumbers);
					$(Hours).insertAfter('.LocationInfoWrapper .ContactInfo:first');


					// highlight current day of week for business hours 
					var FindDate = new Date();
					var WeekDays = FindDate.getDay();
					$('.LocationInfo .Hours .DaySchedule, .LocationInfo .Hours .HoursOfOperation').eq(WeekDays).addClass('TodaysWeekday');

					if (WeekDays == 0) {
						$('.Day:contains("Sunday"), .DayOfWeek:contains("Sunday")').addClass('Today');
					} else if (WeekDays == 1) {
						$('.Day:contains("Monday"), .DayOfWeek:contains("Monday")').addClass('Today');
					} else if (WeekDays == 2) {
						$('.Day:contains("Tuesday"), .DayOfWeek:contains("Tuesday")').addClass('Today');
					} else if (WeekDays == 3) {
						$('.Day:contains("Wednesday"), .DayOfWeek:contains("Wednesday")').addClass('Today');
					} else if (WeekDays == 4) {
						$('.Day:contains("Thursday"), .DayOfWeek:contains("Thursday")').addClass('Today');
					} else if (WeekDays == 5) {
						$('.Day:contains("Friday"), .DayOfWeek:contains("Friday")').addClass('Today');
					} else {
						$('.Day:contains("Saturday"), .DayOfWeek:contains("Saturday")').addClass('Today');
					}

				} else {
					ContactInformation = '<li class="ContactInfo">' +
						'<div class="vcard">';
					ContactInformation += '<div class="adr">';

					if (locImage.length) {
						if (webSiteLink.length) {
							ContactInformation += '<strong><a href="' + webSiteLink + '">' + locImage + '<span class="Name">' + locNameText + '</span></a></strong>';
						} else {
							ContactInformation += '<strong><a href="/app/locations/profile.aspx?navigationNode=' + NavNode + '&amp;id=' + (data.LocationId) + '">' + locImage + '<span class="Name">' + locNameText + '</span></a></strong>';
						}
					} else {
						if (webSiteLink.length) {
							ContactInformation += '<strong><a href="' + webSiteLink + '"><span class="Name">' + locNameText + '</span></a></strong>';
						} else {
							ContactInformation += '<strong><a href="/app/locations/profile.aspx?navigationNode=' + NavNode + '&amp;id=' + (data.LocationId) + '"><span class="Name">' + locNameText + '</span></a></strong>';
						}
					}

					if (locAddress.length) {
						ContactInformation += '<p><span class="street-address">' + locAddress + '</span>';
					}
					if (locAddress2.length) {
						ContactInformation += '<span class="street-address">' + locAddress2 + '</span>';
					}
					if (locCity.length && locState.length && locZip.length) {
						ContactInformation += '<span class="locality">' + locCity + '</span><text>,</text> <span class="region"><abbr title="' + locStateName + '">' + locState + '</abbr></span> <span class="postal-code">' + locZip + '</span>';
					} else {
						if (locCity.length) {
							ContactInformation += '<span class="locality">' + locCity + '</span>';
						}
						if (locState.length) {
							ContactInformation += '<span class="region"><abbr title="' + locStateName + '">' + locState + '</abbr></span>';
						}
						if (locZip.length) {
							ContactInformation += '<span class="postal-code">' + locZip + '</span>';
						}
					}
					ContactInformation += '</p>';

					if (drivingDirections.length) {
						ContactInformation += drivingDirections;
					}

					ContactInformation += '</div>';

					if (phoneMain !== null) {

						PhoneNumbers = '<div class="Contact"><p class="Phone">';

						if (phoneMain !== null) {
							PhoneNumbers += '<span class="sr-only">Phone: </span> ' +
								'<a href="tel:' + phoneMain + '">' + phoneMain + '</a>';
						}

						PhoneNumbers += '</p></div>';
					}

					ContactInformation += PhoneNumbers + '</li>';

					$('.RelatedLocations .LocationWrap').append(ContactInformation);
				}

				// After everything is finished running
				if (markersArray.length === locationsArray.length) {
					// Remove preloader image
					$('.RelatedLocations #loadingmessage').remove();

					initializeMap(markersArray);

					// Turn Array into String 
					var locTermIdString = locTermId.join();

					googleMapHeight();
				}
			});
		});		
	} // end each locations function
});